<template>
  <div class="flex items-center gap-0.5 w-max relative">
    <div class="slider slider-low" />
    <div class="slider-divider" />
    <div class="slider slider-high" />
    <!-- Marker -->
    <div
      class="absolute top-0 bottom-0 my-auto marker"
      :class="markerClass"
      :style="{ left: `${markLocation}px`, width: markerWidth }"
    >
    <!--  -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentageChangeSlider',
  props: {
    range: {
      type: Number,
      default: () => 100
    },
    value: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    const fullWidth = 75
    const markerWidth = 5
    return {
      fullWidth,
      markerWidth
    }
  },
  computed: {
    markerClass () {
      if (this.markLocation > this.fullWidth / 2) {
        return 'marker-positive'
      }
      return 'marker-neutral'
    },
    markLocation () {
      const xPos = ((this.value + this.range) / (2 * this.range)) * this.fullWidth
      return Math.min(75, xPos - this.markerWidth / 2)
    }
  }
}
</script>

<style scoped>
.slider-divider{
  height: 3px;
  width: 3px;
  border-radius: 3px;
  background-color: #303546;
}
.slider{
  height: 3px;
  width: 34px;
  border-radius: 3px;
}
.slider-low{
  background-color: #DFE1E7;
}
.slider-high{
  background-color: #D1f4ee;
}
.marker{
  width: 5px;
  border-radius: 4px;
  height: 12px;
}
.marker-positive{
  background-color: #184E44;
  border: 1.5px solid #D1f4ee;
}
.marker-neutral{
  background-color: white;
  border: 1.5px solid #DFE1E7;
}
</style>
