<template>
  <div class="relative border border-border-normal rounded-2xl overflow-hidden">
    <!-- TODO @Sam: enable when anas has import status -->
    <!-- <div
      v-if="lensInsightAds.length === 0"
      class="backdrop-filter backdrop-blur-sm absolute top-0 left-0 w-full h-full bg-neutral-alpha-650 rounded-2xl flex items-center justify-center flex-col"
    >
      <BaseLoadingSpinnerCircle
        :width="24"
        :height="24"
        class="text-text-muted"
      />
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted"
      >
        Hang tight — we're importing your data
      </BaseText>
      <BaseText
        size="sm"
        class="text-text-normal max-w-xs text-center"
      >
        This can take up to 10 minutes.
      </BaseText>
    </div> -->
    <div class="flex items-center justify-between p-2 pl-4 border-b border-border-normal">
      <div class="w-full flex items-center gap-2">
        <!-- Header -->
        <img
          v-if="data?.avatar"
          :src="data?.avatar"
          class="w-5 h-5  rounded-md"
        >
        <div
          v-else
          class="w-5 h-5 bg-neutral-400 flex items-center justify-center text-background-hover p-1"
          style="border-radius: 4px;"
        >
          <BaseText
            size="xs"
          >
            {{ getIsPrivateMode ? '••' : initialName }}
          </BaseText>
        </div>
        <BaseText
          type="label"
          size="sm"
        >
          {{ getIsPrivateMode ? '•••••' : data.name }}
        </BaseText>
        <BaseText
          size="sm"
          class="text-text-normal"
        >
          {{ data?.report_count }} reports
        </BaseText>
      </div>
      <div class="flex items-center gap-2">
        <router-link :to="{ name: 'LensDashboardView', params: { lensId: data.id } }">
          <div class="px-2 pr-2.5 py-1.5 rounded-md hover:bg-background-normal transition-colors flex items-center gap-1.5">
            <PreviewIcon class="text-icon-normal" />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              View
            </BaseText>
          </div>
        </router-link>
        <button
          class="px-2 pr-2.5 py-1.5 rounded-md hover:bg-background-normal transition-colors flex items-center gap-1.5"
          @click="handleShowLensSettings"
        >
          <SettingsIcon class="text-icon-normal" />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-12">
      <div
        v-if="lensInsightAds.length > 0"
        class="col-span-4 space-y-2 border-r border-border-normal px-4 py-3"
      >
        <div
          v-for="(topAd,index) in lensInsightAds"
          :key="index"
          class="p-1"
        >
          <div class="flex justify-center items-center gap-3">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              {{ index+1 }}
            </BaseText>
            <div
              class="overflow-hidden rounded-md"
            >
              <img
                :src="topAd.ad_image_url"
                :style="{ filter: getIsPrivateMode ? 'blur(8px)' : 'none' }"
                class="w-16 h-16 object-cover rounded-md"
              >
            </div>
            <div class="flex-1 space-y-3 flex-shrink  min-w-0">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted truncate"
              >
                {{ getIsPrivateMode ? '•••••••••••••' : topAd.ad_name }}
              </BaseText>
              <div class="flex items-center gap-3 flex-wrap">
                <div class="flex gap-1">
                  <BaseText
                    size="sm"
                    class="text-text-normal"
                  >
                    Spend
                  </BaseText>
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-normal"
                  >
                    {{ getIsPrivateMode ? '•••••' : formatNumber(topAd.spend, 'currency') }}
                  </BaseText>
                </div>
                <div class="flex items-center gap-1">
                  <BaseText
                    size="sm"
                    class="text-text-normal"
                  >
                    ROAS
                  </BaseText>
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-normal"
                  >
                    {{ getIsPrivateMode ? '•••••' : formatNumber(topAd.roas, 'decimal') }}
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="col-span-4 space-y-2 border-r border-border-normal px-4 py-3 flex items-center justify-center w-full h-full"
      >
        <BaseText
          size="sm"
          class="text-text-normal max-w-xs text-center"
        >
          No past ads found
        </BaseText>
      </div>
      <div class="col-span-8 flex flex-col">
        <div class="grid grid-cols-12">
          <div
            v-for="(stat, index) in lensStats"
            :key="index"
            class="col-span-4"
          >
            <div
              class="h-28 gap-2 p-4  border-b border-border-normal"
              :class="index !== lensStats.length - 1 ? 'border-r' : ''"
            >
              <div class="space-y-2">
                <div class="flex items-center gap-1">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-normal"
                  >
                    {{ stat.title }}
                  </BaseText>
                  <div
                    v-if="stat.tooltip"
                    class="relative"
                  >
                    <InfoIcon
                      class="cursor-pointer text-icon-normal tooltip-trigger"
                      :width="20"
                      :height="20"
                    />
                    <transition>
                      <div
                        class="tooltip-container text-center px-2 py-1 bg-neutral-800 rounded-md shadow-md cursor-default"
                        style="width: 140px;"
                      >
                        <BaseText
                          type="label"
                          size="xs"
                          class="text-white"
                        >
                          {{ stat.tooltip }}
                        </BaseText>
                      </div>
                    </transition>
                  </div>
                </div>
                <BaseText
                  type="heading"
                  class="text-text-muted"
                >
                  {{ getIsPrivateMode ? '••••••••••' : stat.value }}
                </BaseText>
              </div>
              <div class="mt-3">
                <PercentageChangeIndicator
                  :percent="stat.previousPeriod"
                  period-text="since last week"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="p-4">
          <div class="flex gap-1.5">
            <BenchmarkIcon />
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Benchmarks
            </BaseText>
            <BaseText
              size="sm"
              class="text-text-normal capitalize"
            >
              {{ benchmarkCopy }}
            </BaseText>
          </div>
          <div class="flex flex-wrap gap-y-3 mt-3">
            <div
              v-for="benchmark in benchmarkStats"
              :key="benchmark.metricKey"
              class="grid grid-cols-12 w-full place-items-center justify-items-start"
              style="max-width: 50%"
            >
              <BaseText
                class="w-1/4 text-text-normal uppercase col-span-3"
                size="sm"
              >
                {{ benchmark.metricKey }}
              </BaseText>
              <div class="col-span-2 justify-self-end mr-2">
                <BaseText
                  class="text-text-subdued text-left"
                  size="sm"
                >
                  {{ getIsPrivateMode ? '•••' : formatNumber(benchmark.avg) }}
                </BaseText>
              </div>
              <div class="col-span-2">
                <BaseText
                  type="label"
                  size="sm"
                  :class="benchmark.textColor"
                >
                  {{ getIsPrivateMode ? '•••' : formatNumber(benchmark.val) }}
                </BaseText>
              </div>
              <div
                v-show="benchmark.val"
                class="col-span-3"
              >
                <PercentageChangeSlider
                  :value="benchmark.percentDiff"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

import PercentageChangeIndicator from './PercentageChangeIndicator.vue'
import PercentageChangeSlider from './PercentageChangeSlider.vue'
// Icons
import InfoIcon from '../globals/Icons/InfoSimpleIcon.vue'
import BenchmarkIcon from '../globals/Icons/LensIcons/BenchmarkIcon.vue'
import PreviewIcon from '../globals/Icons/PreviewIcon.vue'
import SettingsIcon from '../globals/Icons/SettingsIcons/SettingsIcon.vue'
export default {
  name: 'LensOverviewCard',
  components: {
    PercentageChangeIndicator,
    PercentageChangeSlider,
    // Icons
    InfoIcon,
    BenchmarkIcon,
    PreviewIcon,
    SettingsIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const STAT_MAP = {
      spend: {
        title: 'Spend',
        tooltip: 'Total amount spent on ads in the past week',
        type: 'currency'
      },
      goal_metric: {
        title: '',
        tooltip: '',
        type: 'decimal'
      },
      creatives_count: {
        title: 'New Creatives',
        tooltip: 'Number of creatives used in the past week',
        type: 'decimal',
        previousKey: 'creatives'
      }
    }

    const BENCHMARK_KEYS = ['cpa', 'cpm', 'cvr', 'roas', 'cpc']

    return {
      STAT_MAP,
      BENCHMARK_KEYS
    }
  },
  computed: {
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    ...mapGetters('MiscModule', ['getIsPrivateMode']),
    benchmarkCopy () {
      const gmv = this.data?.gmv ? (this.data.gmv >= 1000000
        ? `${(this.data.gmv / 1000000).toFixed(0).replace(/\.0$/, '')}M`
        : `${(this.data.gmv / 1000).toFixed(0).replace(/\.0$/, '')}K`)
        : ''
      const aov = this.data?.aov_min || null
      const niche = this.data?.niche ? `${this.data.niche.replace(/_/g, ' ')}` : ''
      const showAov = aov && !this.getIsPrivateMode
      const showGmv = gmv && !this.getIsPrivateMode

      const aovText = showAov ? `>$${aov} AOV` : ''
      const gmvText = showGmv ? `>$${gmv} GMV` : ''

      return `${niche}${showAov ? ', ' : ''} ${aovText}${showGmv ? ', ' : ''} ${gmvText}`
    },
    benchmarkStats () {
      return this.BENCHMARK_KEYS.map((key) => {
        const formattedKey = `channel_reported_${key}`
        const benchData = this.data.benchmarks
        const metricVal = this.data.insights?.[key]
        const metricAvg = benchData.avg?.[formattedKey]
        const valDiff = ((metricVal - metricAvg) / metricAvg) * 100
        const textColor = valDiff < 0 ? 'text-secondary-red-200' : 'text-secondary-green-200'
        return {
          metricKey: key,
          val: this.data.insights?.[key] || 0,
          avg: benchData.avg?.[formattedKey] || 0,
          percentDiff: valDiff,
          textColor
        }
      })
    },
    lensStats () {
      if (!this.data?.insights) return []
      const statKeys = Object.keys(this.STAT_MAP)
      const lensInsight = this.data.insights
      const goalMetric = this.data?.goal_metric
      const previousPeriod = this.data.insights.previous_period

      return statKeys.map((insightKey) => {
        const title = this.STAT_MAP[insightKey].title
        const tooltip = this.STAT_MAP[insightKey].tooltip
        const insightType = this.STAT_MAP[insightKey].type
        const previousKeyType = this.STAT_MAP[insightKey].previousKey

        if (insightKey === 'goal_metric') {
          const goalMetricDesc = this.getMetricLookup[goalMetric]?.description
          const goalVal = lensInsight?.[goalMetric]
          const prevGoalVal = previousPeriod?.[goalMetric]
          const periodDiff = ((goalVal - prevGoalVal) / prevGoalVal) * 100
          return {
            title: `Goal Metric Avg. ${goalMetric.toUpperCase()}`,
            tooltip: goalMetricDesc || '',
            value: this.formatNumber(lensInsight?.[goalMetric], insightType) || 0,
            previousPeriod: periodDiff
          }
        }
        const metricVal = lensInsight?.[insightKey]
        const prevMetricVal = previousPeriod?.[previousKeyType ?? insightKey]
        const periodDiff = ((metricVal - prevMetricVal) / prevMetricVal) * 100
        return {
          title,
          tooltip,
          value: this.formatNumber(lensInsight?.[insightKey], insightType) || 0,
          previousPeriod: periodDiff
        }
      })
    },
    lensInsightAds () {
      return this.data?.insights?.ads || []
    },
    initialName () {
      const name = this.data?.name
      if (!name) return ''
      const initials = name.split(' ').map(word => word[0]).join('').toUpperCase()
      return initials.substring(0, 2) // Limit to 2 characters
    }
  },
  methods: {
    ...mapMutations('MiscModule', ['SET_SETTINGS_DRAWER_VIEW_MODE']),
    ...mapMutations('RouterModule', ['SET_CURRENT_ROUTE_ID']),
    handleShowLensSettings () {
      const lensId = this.data.id
      this.SET_SETTINGS_DRAWER_VIEW_MODE('lens')
      this.SET_CURRENT_ROUTE_ID(lensId)
    },
    formatNumber (value, type = 'decimal') {
      if (type === 'currency') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: this.data.currency || 'USD'
        }).format(value)
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2
        }).format(value)
      }
    }
  }
}
</script>

<style scoped>
.tooltip-container{
  pointer-events: none;
  position: absolute;
  bottom: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%) translateY(0);
  opacity: 0;
}
.tooltip-trigger:hover + .tooltip-container{
  opacity: 1
}
</style>
